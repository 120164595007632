<script>
export default {
	data() {
		return {
			parentDivStyler: null,
			scaleValue: null,
		}
	},
	props: {
		hotspotData: Object,
		double: Boolean,
	},

	computed: {
		containerStyler() {
			return {
				paddingTop: `${(this.hotspotData.image.data.height / this.hotspotData.image.data.width) * 100}%`,
				position: 'relative',
				width: '100%',
			}
		},
	},
	methods: {
		getDotStyle(position) {
			let x = Number(position.x.replace('px', ''))
			let y = Number(position.y.replace('px', ''))

			return {
				position: 'absolute !important',
				top: this.$b.mt ? `${y - 20}px !important` : `${position.y} !important`,
				left: this.$b.mt ? `${x - 20}px !important` : `${position.x} !important`,
			}
		},
	},
	mounted() {
		this.$nextTick(() => {
			let container = this.$refs.container

			let { width: containerW } = container.getBoundingClientRect()

			this.scaleValue = containerW / this.hotspotData.image.data.width

			this.parentDivStyler = {
				width: `${this.hotspotData.image.data.width}px`,
				height: `${this.hotspotData.image.data.height}px`,
				transform: `scale(${this.scaleValue}, ${this.scaleValue})`,
			}
		})
	},
}
</script>

<template>
	<div class="h100 w100">
		<div ref="container" v-bind:style="containerStyler">
			<div
				ref="parentDiv"
				v-bind:style="{
					position: 'absolute',
					top: 0,
					left: 0,
					transformOrigin: '0 0',
					...parentDivStyler,
				}"
			>
				<Media :src="hotspotData.image">
					<template>
						<v-menu
							v-for="(item, i) of hotspotData.dotsPositions"
							:key="i"
							offset-x
							offset-y
							offset-overflow
							:max-width="$b.m ? '124px' : '224px'"
							:max-height="$b.m ? '160px' : '250px'"
						>
							<template v-slot:activator="{ on, attrs }">
								<div
									class="dot-container d-flex justify-center align-center"
									:style="getDotStyle(item)"
									v-on="on"
									v-bind="attrs"
								>
									<span class="hotspot-selector-dots" :id="`dot-${i + 1}`"></span>
								</div>
							</template>

							<ProductCard :product="hotspotData.products[i]" :list-index="i" square />
						</v-menu>
					</template>
				</Media>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.dot-container {
	border-radius: 50%;
	background: rgba(17, 17, 17, 0.4);
	cursor: pointer;
	height: 2rem;
	width: 2rem;
	transition: border-color 250ms ease-in-out, opacity 250ms ease-in-out, visibility 250ms ease-in-out;
	border: 2px solid transparent;
}

.dot-container:hover {
	border-color: rgba(255, 255, 255, 0.5);
	background: rgba(17, 17, 17, 0.6);
}
.hotspot-selector-dots {
	height: 0.75rem;
	width: 0.75rem;
	transition: transform 250ms ease-in-out;
	box-shadow: 0 1px 4px rgba(17, 17, 17, 0.55);
	background: rgb(255, 255, 255) !important;
	color: rgb(72, 72, 72) !important;
	position: unset;
	border-radius: 50%;
}

.dot-container:hover .hotspot-selector-dots {
	transform: scale(0.7);
}

::v-deep .product-card-sq-wrapper-mobile {
	height: 160px;
	width: 124px;
}

::v-deep .product-card-sq {
	&__event-icon-mobile,
	&__event-icon-mobile img {
		width: 15px;
	}

	&__price-mobile {
		font-size: 1rem;
		line-height: 1rem;
		margin-top: 0 !important;
	}

	&__prev-price-mobile,
	&__discount-mobile {
		font-size: 0.75rem;
		line-height: 1rem;
	}

	&__prev-prices-mobile {
		align-items: baseline !important;
	}

	&__name-mobile {
		margin-bottom: 4px !important;
		font-size: 0.8rem;
		line-height: 1.1rem;
	}
}

::v-deep img.sq__image--smallWidth-tablet {
	max-width: 5em;
	max-height: 6em;
	left: 50% !important;
	translate: -50%;
}

@media (max-width: 600px) {
	.dot-container {
		height: 95px;
		width: 95px;
		border: 3px solid transparent;
	}
	.hotspot-selector-dots {
		height: 45px;
		width: 45px;
	}

	::v-deep div.sq__event-icon {
		left: 0;
	}
}

@media (min-width: 600px) and (max-width: 960px) {
	.dot-container {
		height: 55px;
		width: 55px;
		border: 3px solid transparent;
	}
	.hotspot-selector-dots {
		height: 20px;
		width: 20px;
	}

	::v-deep div.sq__event-icon {
		left: 0;
	}
}
</style>
