<script>
export default {
	props: {
		index: Number,
		data: Object,
	},
}
</script>

<template>
	<Container
		v-if="data"
		class="d-flex justify-center align-center flex-wrap"
		:class="{ 'flex-row-reverse': data.imgPosition === 'right', 'flex-wrap': $b.m }"
	>
		<div :style="{ width: (!$b.m ? data.columnSize1 : 100) + '%' }" class="pa-5">
			<Media width="100%" :src="$b.d ? data.imgDesktop : data.imgMobile" class="mx-auto" />
		</div>
		<div :style="{ width: (!$b.m ? data.columnSize2 : 100) + '%' }" class="pa-5">
			<SafeLink v-if="data.title" :to="data.titleLink || ''">
				<div class="header is-heading font-6 mb-4">{{ data.title }}</div>
			</SafeLink>
			<div v-html="data.richText" v-if="data.richText"></div>
			<div
				class="mt-4 pa-3 d-flex"
				style="gap: 25px"
				:class="$b.props({ d: 'justify-start', m: 'justify-center' })"
			>
				<SafeLink v-if="data.useCtaBtn1" :to="data.ctaBtn1Url">
					<Button tile large depressed :color="$vars.primary">
						<span style="color: var(--primarytext)" class="font-2">{{ data.ctaBtn1Text }}</span>
					</Button>
				</SafeLink>
				<SafeLink v-if="data.useCtaBtn2" :to="data.ctaBtn2Url">
					<Button tile large depressed :color="$vars.secondary">
						<span style="color: var(--secondarytext)" class="font-2">{{ data.ctaBtn2Text }}</span>
					</Button>
				</SafeLink>
			</div>
		</div>
	</Container>
</template>

<style></style>
