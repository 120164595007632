<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'

export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
	computed: {
		bannerItems() {
			return numberedPropsToArray(this.data, 2)
		},
	},
}
</script>

<template>
	<Container>
		<v-row>
			<v-col cols="12" sm="6" v-for="(i, index) of bannerItems" :key="index">
				<BannerItem v-bind="i" />
			</v-col>
		</v-row>
	</Container>
</template>
