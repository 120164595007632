var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[(_vm.data.title)?_c('Title',{attrs:{"title":_vm.data.title,"titleStyle":_vm.data.titleStyle}}):_vm._e(),(!_vm.isFixed)?_c('v-row',_vm._l((_vm.data.columns),function(column,n){return _c('v-col',{key:n,attrs:{"cols":"12","md":12 / _vm.data.columns.length}},[_c('v-expansion-panels',{model:{value:(_vm.activePanels[n]),callback:function ($$v) {_vm.$set(_vm.activePanels, n, $$v)},expression:"activePanels[n]"}},_vm._l((column.items),function(item,k){return _c('v-expansion-panel',{key:k,staticClass:"my-2 v-expansion-panel--next-active",class:item.type === 'image' ? 'rounded-xl' : ''},[_c('v-expansion-panel-header',{class:{
							'pa-0': item.type == 'image',
							'rounded-b-0': item.type == 'image' && _vm.activePanels[n] == k,
						},staticStyle:{"background-color":"unset","border-color":"unset"},attrs:{"color":_vm.$vars.primary,"hide-actions":item.type !== 'text'},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.$vars.cta}},[_vm._v(" $expand ")])]},proxy:true}],null,true)},[(item.type === 'text')?_c('div',{staticClass:"d-flex align-center",class:item.invertTitle && 'flex-row-reverse justify-end'},[(item.titleIconType === 'icon')?_c('v-icon',[_vm._v(_vm._s(item.titleIcon))]):(item.titleIconType === 'image')?_c('Media',{attrs:{"src":item.titleImage,"width":"50px"}}):_vm._e(),_c('div',{staticClass:"pr-3"}),_c('span',{staticClass:"title basefont"},[_vm._v(_vm._s(item.title))])],1):_c('Media',{class:_vm.activePanels[n] == k ? 'rounded-xl rounded-b-0' : 'rounded-xl',attrs:{"src":item.titleFullImage,"imgClass":_vm.activePanels[n] == k ? 'rounded-xl rounded-b-0' : 'rounded-xl'}})],1),_c('v-expansion-panel-content',{class:{
							'pa-0 rounded-xl rounded-t-0': item.type == 'image',
						},attrs:{"color":_vm.$vars.secondary}},[_c('div',{staticClass:"content pa-4",domProps:{"innerHTML":_vm._s(item.content)}})])],1)}),1)],1)}),1):_c('div',[_c('v-expansion-panels',{staticStyle:{"gap":"10px"},model:{value:(_vm.fixedActivePanel),callback:function ($$v) {_vm.fixedActivePanel=$$v},expression:"fixedActivePanel"}},_vm._l((_vm.data.fixedImages),function(item,k){return _c('div',{key:k},[_c('v-expansion-panel',{staticClass:"my-2 v-expansion-panel--next-active rounded-xl"},[_c('v-expansion-panel-header',{staticClass:"pa-0",class:{
							'rounded-b-0': _vm.fixedActivePanel == k,
						},staticStyle:{"background-color":"unset","border-color":"unset"},attrs:{"color":_vm.$vars.primary,"hide-actions":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.$vars.cta}},[_vm._v(" $expand ")])]},proxy:true}],null,true)},[_c('Media',{class:_vm.fixedActivePanel == k ? 'rounded-xl rounded-b-0' : 'rounded-xl',attrs:{"width":"380","height":"226","src":item.image,"imgClass":_vm.fixedActivePanel == k ? 'rounded-xl rounded-b-0' : 'rounded-xl'}})],1),_c('v-expansion-panel-content',{staticClass:"pa-0 rounded-xl rounded-t-0",staticStyle:{"max-width":"380px"},attrs:{"color":_vm.$vars.secondary}},[_c('div',{staticClass:"content pa-4",domProps:{"innerHTML":_vm._s(item.content)}})])],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }