<template>
	<v-card flat :class="$bem('wordpress-post-card', 'primary')">
		<v-row>
			<v-col cols="12" sm="6">
				<img :alt="post.title" :src="post.image" :class="$bem('__image')" />
			</v-col>
			<v-col cols="12" sm="6">
				<div class="py-2 px-4">
					<div class="header font-4 my-2">{{ post.title }}</div>
					<div class="secondary mb-1 font-0">{{ post.categories.join(', ').toUpperCase() }}</div>
				</div>
			</v-col>
		</v-row>
		<div class="mt-2 pa-4">
			<div v-html="post.excerpt" :class="$bem('__description')"></div>
			<div class="link--text">Leer más <v-icon small>mdi-chevron-right</v-icon></div>
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		post: Object,
	},
}
</script>

<style lang="scss" scoped>
.wordpress-post-card {
	color: var(--basetext);
	border: 1px solid #eee;
	height: 100%;

	&__image {
		width: 100%;
		max-height: 282px;
		object-fit: cover;
	}

	&__description {
		& ::v-deep p {
			max-height: 80px;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			display: -webkit-box;
		}
	}
}
</style>
