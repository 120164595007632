<script>
export default {
	props: {
		data: Object,
	},
	computed: {
		cards() {
			if (this.data.boardType == 2) {
				return this.data.cardsTwoCol
			} else {
				return this.data.cardsFourCol
			}
		},
		dense() {
			return this.data.design == 'dense'
		},
	},
}
</script>

<template>
	<Container>
		<v-row :class="{ 'offer-card-container': !$b.m && data.useCardLines }" class="justify-center">
			<v-col
				v-for="(item, i) of cards"
				:key="i"
				class="offer-card-wrapper"
				:class="dense && 'd-flex justify-center'"
				:cols="dense ? 6 : 12"
				:md="$b({ td: data.boardType == 2 ? 6 : 3 })"
			>
				<OfferCard
					:card="item"
					:type="data.boardType"
					class="offer-card pa-1"
					:class="dense && 'dense-card'"
				/>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
.dense-card {
	max-width: 220px;
}
.offer-card-container {
	.offer-card-wrapper:not(:last-child) {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			height: 1px;
			width: 50px;
			background-color: var(--secondary);
			top: 50%;
			right: -25px;
		}
	}
}
</style>
