<template>
	<v-card flat :class="$bem('wordpress-post-card', 'primary')">
		<img :alt="post.title" :src="post.image" :class="$bem('__image')" />
		<div class="py-2 px-6">
			<div class="header font-4 my-2">{{ post.title }}</div>
			<div class="secondary mb-1 font-0">{{ post.categories.join(', ').toUpperCase() }}</div>
			<div v-html="post.excerpt" :class="$bem('__description', 'pb-6')"></div>
			<div :class="$bem('__link', 'link--text')">Leer más <v-icon small>mdi-chevron-right</v-icon></div>
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		post: Object,
	},
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.wordpress-post-card {
	border: 1px solid #eee;
	height: 100%;
	position: relative;

	&__image {
		@media #{map-get($display-breakpoints, 'md-and-up')} {
			height: 182px;
		}
		width: 100%;
		height: 182px;
		object-fit: cover;
	}

	&__description {
		& ::v-deep p {
			max-height: 80px;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>
