<script>
export default {
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			tab: 0,
		}
	},
	methods: {
		positionIs(position) {
			return this.data.position === position
		},
	},
	computed: {
		carouselConfig() {
			return this.$b.m
				? { slidesPerPage: 2, gutter: 5 }
				: this.$b.d && (this.data.position != 'top' || this.hasBoardProduct)
				? { slidesPerPage: 3 }
				: this.$b.d && this.data.position == 'top'
				? { slidesPerPage: 4 }
				: this.$b.t
				? { slidesPerPage: 3 }
				: null
		},
		left() {
			return this.$b.d && this.positionIs('left')
		},
		right() {
			return this.$b.d && this.positionIs('right')
		},
		top() {
			return this.$b.mt || this.positionIs('top')
		},
		limitedTimeOffer() {
			let offer = this.$store.get('shop/limitedTimeOffer')
			return offer?.find((item) =>
				this.data.boardProduct.collectionsIds.find((el) => item.collectionId == el)
			)
		},

		limitedTimeOfferDate() {
			const date = this.limitedTimeOffer?.untilDate

			const fecha = new Date(date).toLocaleDateString('es-AR', {
				day: 'numeric',
				month: 'long',
				timeZone: 'UTC',
			})
			return `La oferta termina el ${fecha}`
		},
		hasBoardProduct() {
			return this.data.boardEnabled && this.data.boardProduct
		},
		columnsAmount() {
			if (this.hasBoardProduct) {
				if (this.$b.d) return 9
				else return 12
			} else return 12
		},
	},
}
</script>

<template>
	<Container v-if="data" :class="$bem('carousel-multiple')">
		<Title
			v-if="data.textEnabled"
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row
			:class="{
				'flex-column-reverse': data.inverted && hasBoardProduct && $b.mt,
				'flex-row-reverse': data.inverted && hasBoardProduct && $b.d,
			}"
		>
			<v-col :cols="$b.d ? 3 : 12" v-if="hasBoardProduct">
				<ProductCard
					:product="data.boardProduct"
					:list-id="`related-products-board-product-${data.boardProduct.id}`"
					:verticalCard="$b.mt ? false : true"
					featured
					:timerProduct="limitedTimeOffer ? true : false"
					:position="data.inverted ? 'left' : 'right'"
				/>
			</v-col>
			<v-col :cols="columnsAmount">
				<v-row :class="{ 'flex-row-reverse': right }">
					<v-col :cols="top ? 12 : 2">
						<v-tabs
							show-arrows
							v-model="tab"
							:centered="top"
							:center-active="top"
							:vertical="$b.d && !top"
							:color="$vars.cta"
							class="h100"
							:class="{
								'slider-right': left && $b.d,
								'top-underline': top && $b.d,
								'slider-top mb-3': top,
								'px-2': !top && $b.m,
							}"
							:slider-color="$vars.cta"
							background-color="transparent"
						>
							<v-tab
								v-for="(item, n) in data.tabs"
								:key="n"
								:class="{ 'justify-start': left, 'justify-end': right }"
								class="base"
								active-class="cta--text"
							>
								<v-icon v-if="data.titleType == 'icon'">{{ item.titleIcon }}</v-icon>
								<Media v-else-if="data.titleType == 'img'" :src="item.titleImg" />
								<span v-else>
									{{ item.title }}
								</span>
							</v-tab>
						</v-tabs>
					</v-col>
					<v-col :cols="top ? 12 : 10" :class="{ 'border-left': left, 'border-right': right }">
						<v-tabs-items v-model="tab" touchless style="background: transparent !important">
							<v-tab-item v-for="(item, n) in data.tabs" :key="n" class="pa-1">
								<ProductsCarousel
									list-id="related-products"
									:products="item.products"
									v-bind="carouselConfig"
								/>
							</v-tab-item>
						</v-tabs-items>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
::v-deep .v-tabs-bar--is-mobile::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 86%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

.top-underline ::v-deep .v-slide-group__wrapper::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 97.5%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

::v-deep .v-tab:not(.v-tab--active) {
	color: var(--basetext) !important;
}

.border-left {
	border-left: 1px solid #ddd;
}

.border-right {
	border-right: 1px solid #ddd;
}
.slider-right ::v-deep .v-tabs-slider-wrapper {
	left: auto !important;
	right: 0;
}
::v-deep .v-tab {
	max-width: 100%;
}

::v-deep .v-tabs-slider::before {
	content: ' ';
	display: block;
	position: absolute;
	left: 50%;
	border-radius: 10px;
	transform: translateX(-50%);
	background-color: var(--cta);
	width: 12px;

	height: 11px;
	bottom: 38%;
}

.slider-top ::v-deep .v-tabs-slider::before {
	height: 8px;
	bottom: -1px;
}
</style>
