<script>
export default {
	props: {
		img: [Object, String],
		imgMobile: [Object, String],
		link: String,
		textEnabled: Boolean,
		textPosition: String,
		textTitle: String,
		textSubtitle: String,
		textCTA: String,
	},
}
</script>

<template>
	<SafeLink :to="link" class="w100">
		<Media
			:src="$b.m ? imgMobile : img"
			width="100%"
			heigth="100%"
			:img-class="$b({ td: 'rounded-lg' })"
			fill
		>
			<div
				v-if="textEnabled"
				class="h100"
				:class="{ 'd-flex flex-column justify-center': textPosition == 'Texto dentro' }"
			>
				<!-- TEXTO SOBRE -->
				<div
					v-if="textPosition == 'Texto sobre'"
					class="d-flex flex-column align-center h100 justify-space-between"
					:class="$bem('__text-over')"
				>
					<div class="text-center py-3 px-5 rounded-lg rounded-t-0 primary">
						<h3 :class="{ 'font-0': $b.m }" class="font-weight-bold">
							{{ textTitle }}
						</h3>
						<h4 v-if="textSubtitle" :class="$b.m && 'subtitle-mobile'" class="font-weight-medium">
							{{ textSubtitle }}
						</h4>
					</div>

					<div v-if="textCTA" class="pb-5 py-5 mb-3">
						<v-btn class="cta font-weight-bold" :small="$b.m">
							{{ textCTA }}
						</v-btn>
					</div>
				</div>

				<!-- TEXTO DENTRO -->
				<div
					v-else-if="textPosition == 'Texto dentro'"
					class="w100 h100 background-overlay pa-5 d-flex flex-column justify-center align-center white--text"
					:class="($bem('__text-inside'), { 'rounded-lg': $b.d })"
				>
					<div class="text-center">
						<h3 :class="{ 'font-0': $b.m }" class="font-weight-bold">
							{{ textTitle }}
						</h3>
						<h4 v-if="textSubtitle" :class="{ 'subtitle-mobile': $b.m }" class="font-weight-medium">
							{{ textSubtitle }}
						</h4>
					</div>
					<v-btn class="cta font-weight-bold mt-5" :small="$b.m" v-if="textCTA">
						{{ textCTA }}
					</v-btn>
				</div>

				<!-- TEXTO SEO -->
				<div v-else class="seo-text" :class="$bem('__text-seo')">
					<div>
						<h3>{{ textTitle }}</h3>
						<h4 v-if="textSubtitle">{{ textSubtitle }}</h4>
					</div>
					<div v-if="textCTA">
						<v-btn>{{ textCTA }}</v-btn>
					</div>
				</div>
			</div>
		</Media>
	</SafeLink>
</template>

<style scoped>
.background-overlay {
	background-color: #424242aa;
}

.subtitle-mobile {
	font-size: 0.8em;
}
</style>
