<script>
import carousel from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-CarouselMultiple.vue'

export default {
	extends: carousel,
	data() {
		return {
			active: 0,
			lastIndex: this.$props.data.tabs[0]?.products.length - 1,
		}
	},
	computed: {
		tabs() {
			if (this.$b.mt)
				return this.data?.tabs.map((tab) => {
					// 		if (tab.limit == 12) {

					let res = []
					let [...arr] = tab.products.flatMap((i) => i)

					while (arr.length) {
						res.push(arr.splice(0, 2))
					}

					tab.products = res
					// 		}

					return tab
				})
			else return this.data?.tabs
		},

		hasBoardProducts() {
			return this.data?.boardProducts?.length
		},

		carouselConfig() {
			return this.$b.m
				? { slidesPerPage: 2, gutter: 5 }
				: this.$b.d && this.hasBoardProducts
				? { slidesPerPage: 2, gutter: 5 }
				: this.$b.d
				? { slidesPerPage: 4 }
				: this.$b.t
				? { slidesPerPage: 3 }
				: null
		},

		limitedTimeOffer() {
			let offer = this.$store.get('shop/limitedTimeOffer')
			return offer?.find((item) =>
				this.data?.boardProducts.find((el) => el.collectionsIds.find((c) => item.collectionId == c))
			)
		},
	},

	methods: {
		moveBack() {
			if (this.active > 0) this.active -= 1
		},
		moveForward() {
			if (this.active < this.lastIndex) this.active += 1
		},
	},

	watch: {
		tab(value) {
			this.active = 0
			this.lastIndex = this.tabs[value].products.length - 1
		},
	},
}
</script>

<template>
	<Container v-if="data" :class="$bem('carousel-multiple-pro')">
		<v-row
			:class="{
				'flex-column-reverse': data.inverted && hasBoardProducts && $b.mt,
				'flex-row-reverse': data.inverted && hasBoardProducts && $b.d,
				'mx-1': $b.mt,
			}"
		>
			<v-col
				:cols="$b.d ? 4 : 12"
				v-if="hasBoardProducts"
				:class="$bem('__featured-container')"
				class="secondary px-5 pt-6 align-self-start"
			>
				<div>
					<!-- <span v-if="data.title" class="title font-3 line-clamp-1">{{ data.title }}</span> -->
					<ProductsCarouselForFeaturedCard
						:title="data.title"
						list-id="related-products-board-products"
						:products="data.boardProducts"
						v-bind="
							$b.props({
								slidesPerPage: 1,
							})
						"
						featured
						:timerProduct="limitedTimeOffer ? true : false"
					/>
				</div>
			</v-col>
			<v-col :cols="hasBoardProducts && $b.d ? 8 : 12">
				<v-row :class="{ 'flex-row-reverse': right }">
					<v-col cols="12" :sm="12" class="py-0">
						<v-tabs
							show-arrows
							v-model="tab"
							centered
							center-active
							:color="$vars.cta"
							class="h100"
							:class="{
								'carousel-multiple-pro__top-underline': $b.d,
								'carousel-multiple-pro__slider-top mb-3': true,
							}"
							:slider-color="$vars.cta"
						>
							<v-tab
								v-for="(item, n) in data.tabs"
								:key="n"
								:class="{ 'justify-start': left, 'justify-end': right }"
								active-class="cta--text"
							>
								<v-icon v-if="data.titleType == 'icon'">{{ item.titleIcon }}</v-icon>
								<Media v-else-if="data.titleType == 'img'" :src="item.titleImg" />
								<span v-else>
									{{ item.title }}
								</span>
							</v-tab>
						</v-tabs>
					</v-col>
					<v-col
						cols="12"
						:sm="top ? 12 : 10"
						:class="{
							'carousel-multiple-pro__border-left': left,
							'carousel-multiple-pro__border-right': right,
						}"
						class="py-0 d-flex"
					>
						<div
							v-for="(item, n) in tabs"
							:key="n"
							class="tab"
							:class="{ 'active-tab w100': n == tab }"
						>
							<div class="pa-1" v-if="n == tab" :key="n">
								<div class="d-flex" :class="{ 'justify-end': $b.d }">
									<div
										class="d-flex"
										:class="{ 'w100 carousel-multiple-pro__arrows-container-mobile': $b.mt }"
									>
										<span
											@click="moveBack"
											:class="$bem('__btn-carousel-multiple-back')"
											:style="{
												visibility: active > 0 ? 'visible' : 'hidden',
												pointerEvents: active > 0 ? 'all' : 'none',
											}"
											><v-icon :color="$vars.cta">mdi-chevron-left</v-icon></span
										>
										<span
											@click="moveForward"
											:class="$bem('__btn-carousel-multiple-next')"
											:style="{
												visibility: active < lastIndex ? 'visible' : 'hidden',
												pointerEvents: active < lastIndex ? 'all' : 'none',
											}"
											><v-icon :color="$vars.cta">mdi-chevron-right</v-icon></span
										>
									</div>
								</div>

								<div
									class="w100 tab"
									v-for="(el, index) in item.products"
									:key="index"
									:class="{ 'active-tab': active == index }"
								>
									<div
										class="d-flex flex-wrap"
										v-if="active == index"
										:class="[$bem('__fade-enter-active'), $bem('__fade-leave-active')]"
									>
										<div
											class="d-flex flex-column"
											v-for="(product, i) in el"
											:key="i"
											:class="[
												$b.mt ? 'col-6' : 'col-4 px-2',
												{
													'pl-3 pr-1 align-center': $b.mt && i % 2 == 0,
													'pr-3 pl-1 align-center': $b.mt && i % 2 == 1,
												},
											]"
										>
											<ProductCard
												:product="product"
												:list-index="i"
												list-id="related-products"
												pbForBtnHover="40px"
												:small="$b.mt"
												:forMobileShop="$b.mt"
												:mobileBtn="$b.mt"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
.tab {
	transform: translateX(30px);
	opacity: 0;
	transition: all 0.3s ease-out;
}

.active-tab {
	opacity: 1;
	transform: translateX(0);
}

.carousel-multiple-pro {
	&__slider-right ::v-deep .v-tabs-slider-wrapper {
		left: auto !important;
		right: 0;
	}

	&__top-underline ::v-deep .v-slide-group__wrapper::after {
		content: ' ';
		border-bottom: 1px solid #ddd;
		width: 97.5%;
		position: absolute;
		margin-right: auto;
		margin-left: auto;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__slider-top ::v-deep .v-tabs-slider::before {
		height: 8px;
		bottom: -1px;
	}

	&__border-left {
		border-left: 1px solid #ddd;
	}

	&__border-right {
		border-right: 1px solid #ddd;
	}

	&__arrows-container-mobile {
		justify-content: space-between;
		position: absolute;
		top: 50%;
		z-index: 1;
		left: 0;
	}

	&__btn-carousel-multiple-back,
	&__btn-carousel-multiple-next {
		cursor: pointer;
		position: relative;
	}

	&__btn-carousel-multiple-back {
		right: 8px;
	}

	&__btn-carousel-multiple-next {
		left: 8px;
	}

	&__fade-enter-active,
	&__fade-leave-active {
		animation: fadeInOut 0.5s ease-out;
	}

	&__fade-enter,
	&__fade-leave-to {
		opacity: 0;
	}

	&__featured-container {
		border-radius: 30px;
	}

	// @keyframes fadeInOut {
	// 	from {
	// 		opacity: 0;
	// 		scale: 0.9;
	// 	}
	// 	to {
	// 		opacity: 1;
	// 		scale: 1;
	// 	}
	// }
}

::v-deep .v-tabs-bar--is-mobile::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 86%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

::v-deep .v-tab:not(.v-tab--active) {
	color: var(--basetext) !important;
}

::v-deep .v-tab {
	max-width: 100%;
}

::v-deep .v-tabs-items,
::v-deep .v-tabs > .v-tabs-bar {
	background: transparent !important;
}

::v-deep .v-tabs-slider::before {
	content: ' ';
	display: block;
	position: absolute;
	left: 50%;
	border-radius: 10px;
	transform: translateX(-50%);
	background-color: var(--cta);
	width: 12px;

	height: 11px;
	bottom: 38%;
}

.title {
	position: absolute;
	font-family: var(--strongfont) !important;
}

::v-deep div.product-card-design1-vertical-wrapper,
::v-deep div.product-card-design3-vertical-wrapper,
::v-deep div.product-card-design4-vertical-wrapper,
::v-deep div.product-card-design2-vertical-wrapper,
::v-deep div.product-card-design5-vertical-wrapper,
::v-deep div.product-card-design6-vertical-wrapper,
::v-deep div.product-card-design7-vertical-wrapper,
::v-deep div.product-card-design8-vertical-wrapper {
	width: unset !important;
	min-width: unset !important;
	// min-height: 300px;

	&-forBtn {
		padding-bottom: 30px;
	}
}

@media (max-width: 600px) {
	::v-deep div.product-card-design1-with-timer-vertical-wrapper,
	::v-deep div.product-card-design3-with-timer-vertical-wrapper,
	::v-deep div.product-card-design4-with-timer-vertical-wrapper,
	::v-deep div.product-card-design2-with-timer-vertical-wrapper,
	::v-deep div.product-card-design5-with-timer-vertical-wrapper,
	::v-deep div.product-card-design6-with-timer-vertical-wrapper,
	::v-deep div.product-card-design7-with-timer-vertical-wrapper,
	::v-deep div.product-card-design8-with-timer-vertical-wrapper {
		max-height: 600px;
	}

	::v-deep div.product-card-design1-with-timer-vertical,
	::v-deep div.product-card-design3-with-timer-vertical,
	::v-deep div.product-card-design4-with-timer-vertical,
	::v-deep div.product-card-design2-with-timer-vertical,
	::v-deep div.product-card-design5-with-timer-vertical,
	::v-deep div.product-card-design6-with-timer-vertical,
	::v-deep div.product-card-design7-with-timer-vertical,
	::v-deep div.product-card-design8-with-timer-vertical {
		&__image-cont {
			width: 80%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.title {
		transform: translateX(0);
	}
}

::v-deep div.product-card-design1-vertical,
::v-deep div.product-card-design2-vertical,
::v-deep div.product-card-design3-vertical,
::v-deep div.product-card-design4-vertical,
::v-deep div.product-card-design5-vertical,
::v-deep div.product-card-design6-vertical,
::v-deep div.product-card-design7-vertical,
::v-deep div.product-card-design8-vertical {
	&__name {
		font-size: 1rem;
		line-height: 1.5rem;
		display: -webkit-inline-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-break: normal;
	}

	&__price {
		font-size: 1.7rem;
		line-height: 2rem;
	}

	&__image-cont {
		width: 70%;
		left: 50%;
		transform: translateX(-50%);
	}

	&__event-icon {
		width: 30px;
		bottom: -12px;
		left: 0px;
	}

	img.event-icon {
		width: 30px;
	}
}
</style>

