var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.products && _vm.data.products.length)?_c('Container',{staticClass:"align-center no-mobile-px",class:[
		_vm.$bem('enhanced-products-carousel'),
		{
			'pa-0': _vm.$b.m,
			'p-relative': _vm.imgBoard && _vm.btnHoverCard,
		} ]},[(_vm.data.textEnabled)?_c('Title',{attrs:{"title":_vm.data.title,"btn":{ link: _vm.data.link, linkText: _vm.data.linkText },"titleStyle":_vm.data.titleStyle}}):_vm._e(),_c('div',{class:[_vm.rowClass, { 'p-relative': _vm.data.boardEnabled && _vm.btnHoverCard }]},[(_vm.productBoard || _vm.bgBoard)?_c('v-col',{staticClass:"justify-center d-flex",class:[
				{
					'col-3': _vm.$b.d,
					'col-12': _vm.$b.m,
					'col-11': _vm.$b.t,
				},
				_vm.boardColumnClass ]},[(_vm.productBoard)?_c('div',{class:{ w100: !_vm.$b.t }},[_c('ProductCard',{attrs:{"product":_vm.data.boardProduct,"list-id":_vm.listId,"verticalCard":_vm.$b.mt ? false : true,"featured":"","timerProduct":_vm.limitedTimeOffer ? true : false,"position":_vm.data.inverted ? 'left' : 'right'}})],1):_vm._e(),(_vm.bgBoard)?_c('SafeLink',{staticClass:"w100",class:{ 'rounded-lg': _vm.$b.d },style:(_vm.bgColor),attrs:{"to":_vm.data.bgLink}},[_c('div',{staticClass:"d-flex flex-column align-center h100 w100 px-6",class:[
						{ 'background-overlay': _vm.data.bgImgOverlay, 'rounded-lg': _vm.$b.d },
						_vm.textPosition,
						_vm.$b.mt ? 'py-10' : 'py-4' ]},[_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.data.bgTitle)?_c('h3',{staticClass:"font-weight-bold line-clamp-3 strong",class:{ 'font-4': _vm.$b.mt, 'font-5 mb-1': _vm.$b.d }},[_vm._v(" "+_vm._s(_vm.data.bgTitle)+" ")]):_vm._e(),(_vm.data.bgSubtitle)?_c('p',{staticClass:"font-weight-medium line-clamp-3 primary",class:{ 'mb-1 font-2': _vm.$b.mt, 'font-3 mb-3': _vm.$b.d }},[_vm._v(" "+_vm._s(_vm.data.bgSubtitle)+" ")]):_vm._e()]),(_vm.limitedTimeOffer)?_c('div',[_c('LimitedTimeOfferCountdown',{attrs:{"limitedTimeOffer":_vm.limitedTimeOffer,"timerCard":""}}),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-uppercase line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0 text-center"},[_vm._v(" "+_vm._s(_vm.limitedTimeOfferDate)+" ")])])],1):_vm._e()])]):_vm._e()],1):_vm._e(),(_vm.imgBoard)?_c('div',{class:_vm.imgBoardClass},[_c('SafeLink',{attrs:{"to":_vm.data.bgLink}},[_c('Media',{staticClass:"h100 w100",attrs:{"src":_vm.$b.mt ? _vm.data.imgMobile : _vm.data.img,"img-class":_vm.$b({ d: 'rounded-lg' }),"width":_vm.$b.mt && '100%'}},[_c('div',{staticClass:"d-flex flex-column align-center h100 w100 px-6",class:[
							{ 'rounded-lg': _vm.$b.d },
							_vm.data.bgImgOverlay && 'background-overlay',
							_vm.textPosition ]},[_c('div',{staticClass:"d-flex flex-column align-center"},[(_vm.data.bgTitle)?_c('h3',{staticClass:"font-weight-bold line-clamp-3 strong",class:{ 'font-4': _vm.$b.mt, 'font-5 mb-2': _vm.$b.d }},[_vm._v(" "+_vm._s(_vm.data.bgTitle)+" ")]):_vm._e(),(_vm.data.bgSubtitle)?_c('p',{staticClass:"font-weight-medium line-clamp-3 primary--text",class:{ 'mb-1 font-2': _vm.$b.mt, 'font-3 mb-0': _vm.$b.d }},[_vm._v(" "+_vm._s(_vm.data.bgSubtitle)+" ")]):_vm._e()]),(_vm.limitedTimeOffer)?_c('div',[_c('LimitedTimeOfferCountdown',{attrs:{"limitedTimeOffer":_vm.limitedTimeOffer,"timerCard":""}}),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-uppercase line-clamp-3 mb-0 font-weight-medium secondary py-1 px-2 rounded font-0 text-center"},[_vm._v(" "+_vm._s(_vm.limitedTimeOfferDate)+" ")])])],1):_vm._e()])])],1)],1):_vm._e(),_c('div',{staticClass:"w100 no-mobile-px",class:{
				'col pa-0': _vm.data.boardEnabled && _vm.data.boardType != 'img' && _vm.$b.d,
				'enhanced-products-carousel__btnHoverFix': _vm.$b.d && _vm.data.boardEnabled && _vm.btnHoverCard,
			},staticStyle:{"min-width":"0","align-self":"center"}},[_c('ProductsCarousel',_vm._b({attrs:{"products":_vm.data.products,"list-id":_vm.listId,"dotContainerFullWidthMobile":Math.round(_vm.data.products.length / _vm.carouselConfig.slidesPerPage) >= 15,"arrowsBgHalfRounded":!_vm.data.imgEnabled}},'ProductsCarousel',_vm.carouselConfig,false))],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }