<script>
export default {
	props: {
		data: Object,
	},
}
</script>

<template>
	<Container :class="$bem('brands-board')">
		<Title
			:title="data.title"
			:btn="{ link: data.link, linkText: data.textCta }"
			:titleStyle="data.titleStyle"
		/>

		<v-row class="justify-center mt-4">
			<v-col v-for="item of data.brands" :key="item.id" lg="2" md="3" sm="4" cols="6">
				<v-card
					tile
					rounded
					class="text-center pt-md-3 px-md-3 px-1 pt-1 rounded"
					:to="$shop.getShopRoute({ brand: item.brand.urlName })"
					:class="$bem('__brand-card')"
					v-if="item.brand.logo"
				>
					<Media :src="item.brand.logo" width="100%" heigth="100%" fill />
					<v-card-subtitle class="text-center">
						<div
							class="rounded font-weight-medium text-md-body-3 py-2 py-lg-0 discount"
							:class="$b({ sm: 'discount-tagMobile' })"
						>
							<span v-if="item.discountPct && data.showDiscount"
								>Hasta {{ item.discountPct | discountPct }}% OFF</span
							>
							<span v-else>Ver productos</span>
						</div>
					</v-card-subtitle>
				</v-card>
			</v-col>
		</v-row>
	</Container>
</template>

<style scoped>
.discount-tagMobile {
	font-size: 0.9rem;
}
</style>
