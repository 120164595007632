<script>
export default {
	props: {
		index: Number,
		data: Object,
	},
}
</script>

<template>
	<RecursiveHtmlJson
		:data="data.dynamicDpc.compileResult"
		:dynamicData="data.dynamicDpc.dynamicData"
		:methodsCode="data.dynamicDpc.methodsCode"
		:mountedCode="data.dynamicDpc.mountedCode"
		:beforeDestroyCode="data.dynamicDpc.beforeDestroyCode"
		:data-props="data.dynamicDpc.dataProps"
		:css-styles="data.dynamicDpc.cssStyles"
		:cid="data.cid"
	/>
</template>
