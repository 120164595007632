var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('Container',{class:_vm.$bem('carousel-multiple')},[(_vm.data.textEnabled)?_c('Title',{attrs:{"title":_vm.data.title,"btn":{ link: _vm.data.link, linkText: _vm.data.linkText },"titleStyle":_vm.data.titleStyle}}):_vm._e(),_c('v-row',{class:{
			'flex-column-reverse': _vm.data.inverted && _vm.hasBoardProduct && _vm.$b.mt,
			'flex-row-reverse': _vm.data.inverted && _vm.hasBoardProduct && _vm.$b.d,
		}},[(_vm.hasBoardProduct)?_c('v-col',{attrs:{"cols":_vm.$b.d ? 3 : 12}},[_c('ProductCard',{attrs:{"product":_vm.data.boardProduct,"list-id":("related-products-board-product-" + (_vm.data.boardProduct.id)),"verticalCard":_vm.$b.mt ? false : true,"featured":"","timerProduct":_vm.limitedTimeOffer ? true : false,"position":_vm.data.inverted ? 'left' : 'right'}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.columnsAmount}},[_c('v-row',{class:{ 'flex-row-reverse': _vm.right }},[_c('v-col',{attrs:{"cols":_vm.top ? 12 : 2}},[_c('v-tabs',{staticClass:"h100",class:{
							'slider-right': _vm.left && _vm.$b.d,
							'top-underline': _vm.top && _vm.$b.d,
							'slider-top mb-3': _vm.top,
							'px-2': !_vm.top && _vm.$b.m,
						},attrs:{"show-arrows":"","centered":_vm.top,"center-active":_vm.top,"vertical":_vm.$b.d && !_vm.top,"color":_vm.$vars.cta,"slider-color":_vm.$vars.cta,"background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(item,n){return _c('v-tab',{key:n,staticClass:"base",class:{ 'justify-start': _vm.left, 'justify-end': _vm.right },attrs:{"active-class":"cta--text"}},[(_vm.data.titleType == 'icon')?_c('v-icon',[_vm._v(_vm._s(item.titleIcon))]):(_vm.data.titleType == 'img')?_c('Media',{attrs:{"src":item.titleImg}}):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-col',{class:{ 'border-left': _vm.left, 'border-right': _vm.right },attrs:{"cols":_vm.top ? 12 : 10}},[_c('v-tabs-items',{staticStyle:{"background":"transparent !important"},attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(item,n){return _c('v-tab-item',{key:n,staticClass:"pa-1"},[_c('ProductsCarousel',_vm._b({attrs:{"list-id":"related-products","products":item.products}},'ProductsCarousel',_vm.carouselConfig,false))],1)}),1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }