var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[(_vm.hotspotData.title)?_c('div',{class:{
			'modern-row': _vm.hotspotData.titleStyle == 'modern',
			'text-center': _vm.hotspotData.titleStyle == 'short',
			'd-flex align-center justify-center': _vm.hotspotData.titleStyle == 'double',
		}},[(_vm.hotspotData.titleStyle == 'double')?_c('div',{staticClass:"col d-flex double-col pa-0 mr-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]):_vm._e(),(_vm.hotspotData.title)?_c('h2',{staticClass:"font-weight-bold header d-inline-block",class:{
				modern: _vm.hotspotData.titleStyle == 'modern',
				short: _vm.hotspotData.titleStyle == 'short',
				'font-4': _vm.$b.td,
				'font-3': _vm.$b.m,
			}},[_vm._v(" "+_vm._s(_vm.hotspotData.title)+" ")]):_vm._e(),(_vm.hotspotData.titleStyle == 'double')?_c('div',{staticClass:"col d-flex double-col pa-0 ml-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]):_vm._e()]):_vm._e(),(_vm.hotspotData.subtitle)?_c('div',{class:{
			'text-center': _vm.hotspotData.titleStyle == 'short' || _vm.hotspotData.titleStyle == 'double',
			'mt-1': _vm.hotspotData.titleStyle == 'short',
		}},[_c('p',{staticClass:"mb-4 font-2 base--text"},[_vm._v(_vm._s(_vm.hotspotData.subtitle))])]):_vm._e(),_c('div',{staticClass:"d-flex",style:(Object.assign({}, {columnGap: '10px'}, _vm.divStyler))},[_c('HotspotItem',{ref:"hotspotItem",attrs:{"hotspotData":_vm.hotspotData[1]}}),(_vm.hotspotData[2])?_c('HotspotItem',{attrs:{"hotspotData":_vm.hotspotData[2],"double":""}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }