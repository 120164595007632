<script>
export default {
	props: {
		data: Object,
	},

	computed: {
		pxAmount() {
			return `${this.data.pxAmount}px`
		},
	},
}
</script>

<template>
	<div :style="{ height: pxAmount }"></div>
</template>

<style scoped></style>
