<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'

export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
	computed: {
		bannerItems() {
			return numberedPropsToArray(this.data, 6)
		},
	},
}
</script>

<template>
	<Container>
		<v-row>
			<v-col cols="12" md="6">
				<v-row>
					<v-col cols="12">
						<BannerItem v-bind="bannerItems[0]" />
					</v-col>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[1]" />
					</v-col>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[2]" />
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="6">
				<v-row>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[3]" />
					</v-col>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[4]" />
					</v-col>
					<v-col cols="12">
						<BannerItem v-bind="bannerItems[5]" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>
