<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'

export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
	computed: {
		bannerItems() {
			return numberedPropsToArray(this.data, 5)
		},
	},
}
</script>

<template>
	<Container>
		<v-row align="center">
			<v-col cols="12" sm="3">
				<BannerItem v-bind="bannerItems[0]" />
			</v-col>

			<v-col cols="6" v-if="$b({ smAndUp: true })">
				<v-row>
					<v-col cols="12" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[1]" />
					</v-col>
					<v-col cols="12" sm="6" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[2]" />
					</v-col>
					<v-col cols="12" sm="6" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[3]" />
					</v-col>
				</v-row>
			</v-col>

			<v-col v-else>
				<div>
					<BannerItem v-bind="bannerItems[1]" />
				</div>

				<div class="py-6">
					<BannerItem v-bind="bannerItems[2]" />
				</div>

				<div>
					<BannerItem v-bind="bannerItems[3]" />
				</div>
			</v-col>

			<v-col cols="12" sm="3">
				<BannerItem v-bind="bannerItems[4]" />
			</v-col>
		</v-row>
	</Container>
</template>
