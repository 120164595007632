<script>
export default {
	props: {
		data: Object,
	},
}
</script>

<template>
	<div v-if="data && data.html" v-html="data.html"></div>
</template>

<style></style>
