<script>
export default {
	props: {
		data: Object,
	},

	data() {
		return {
			currentHover: null,
			currentHoverHeight: null,
			tab: 0,
		}
	},

	computed: {
		listId() {
			return `${this.data.collectionId}-${this.data.title}`
		},
		textPosition() {
			switch (this.data.textImgPosition) {
				case 'top':
					return 'justify-start pt-4'

				case 'mid':
					return 'justify-center'

				default:
					return 'justify-end pb-4'
			}
		},

		productsLimit() {
			return this.data.enableTabs ? this.data.tabs[this.tab || 0]?.products : this.data?.products
		},

		productsLength() {
			if (this.data.enableTabs) return Math.max(...this.data.tabs.map((t) => t.products.length))
			else return this.productsLimit.length
		},

		colSize() {
			let res
			if (this.$b.td) {
				if (!this.data.imgEnabled) {
					if (this.productsLimit.length <= 4) res = 'col-6'
					else if (this.productsLimit.length > 4 && this.productsLimit.length < 7) res = 'col-4'
					else if (this.productsLimit.length >= 8 && this.productsLimit.length < 12) res = 'col-3'
					else res = 'col-2'
				} else {
					if (this.productsLimit.length == 8) res = 'col-3'
					else if (this.productsLimit.length <= 6 && this.productsLimit.length > 2) res = 'col-6'
					else res = 'col-12'
				}
			} else {
				if (this.productsLimit.length == 1) res = 'col-12'
				else res = 'col-6'
			}
			return res
		},

		hasHoverBtn() {
			let configData = this.$srv('productCardType', {})
			return this.$b.d ? configData?.btnDesktop != 'noBtn' : !configData?.btnMobile != 'noBtn'
		},

		minProductsDesktop() {
			return this.$b.d && this.productsLimit.length === 6
		},
		oddIndexProducts() {
			return this.productsLimit.filter((p, i) => i % 2 != 0)
		},
		evenIndexProducts() {
			return this.productsLimit.filter((p, i) => i % 2 == 0)
		},
		masonryCols() {
			if (!this.data.designType === 'masonry') return
			if (this.$b.d) return this.productsLimit.length <= 6 ? 3 : 4
			else if (this.$b.m) return 2
			else return 4
		},
		masonryMaxWidth() {
			return this.$b.d && this.productsLimit?.length <= 6 ? 'max-width: 815px' : ''
		},
		groupedProducts() {
			if (!this.data.designType === 'masonry') return

			const separatedArrays = new Array(this.masonryCols).fill().map(() => [])

			for (let i = 0; i < this.productsLimit.length; i++) {
				const index = i % this.masonryCols
				separatedArrays[index].push(this.productsLimit[i])
			}

			return separatedArrays
		},
		cardType() {
			return this.data.useCustomCard ? this.data.cardType : undefined
		},
	},

	methods: {
		onHover(ev, n, k) {
			if (ev) {
				let ref = this.$refs[`card-${n}-${k}`][0].$el
				this.currentHover = `${n}-${k}`
				this.currentHoverHeight = ref.clientHeight + 'px'
			} else {
				this.currentHover = null
				this.currentHoverHeight = null
			}
		},
	},

	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container
		v-if="(data.enableTabs && data.tabs && data.tabs.length) || (data.products && data.products.length)"
		class="align-center no-mobile-px"
		:class="($b({ m: 'pa-0' }), $bem('products-board'))"
	>
		<Title
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row
			v-if="!data.designType || data.designType === 'dynamic'"
			:class="[data.enableTabs ? 'align-start' : 'align-center', data.inverted && 'flex-row-reverse']"
		>
			<v-col
				v-if="data.imgEnabled"
				:class="{
					'col-3': $b.d && productsLength <= 4,
					'col-4': $b.d && productsLength > 4,
					'col-12': $b.mt,
				}"
				class="py-0"
			>
				<Media
					:src="$b({ d: data.img, mt: data.imgMobile })"
					cover
					width="100%"
					:img-class="$b({ d: 'rounded-lg' })"
				>
					<div
						class="h100 w100 d-flex flex-column align-center"
						:class="[
							$b({ d: 'rounded-lg', d: textPosition, mt: 'justify-center' }),
							data.textImgOverlay && 'background-overlay',
						]"
						v-if="data.textImgEnabled"
					>
						<h3 class="font-2 font-sm-5 font-md-7 font-weight-bold strong">
							{{ data.titleImg }}
						</h3>
						<Button
							v-if="data.ctaImg"
							:to="data.linkImg"
							text
							class="cta--text px-2"
							:class="{ 'outlined-mobile my-3': $b.m, 'outlined my-6': $b.td }"
						>
							{{ data.ctaImg }}
						</Button>
					</div>
				</Media>
			</v-col>
			<v-col :style="data.imgEnabled && $b({ mt: 'z-index: 1' })">
				<v-row
					v-if="!data.enableTabs"
					:class="data.imgEnabled && productsLimit.length < 4 && $b({ t: 'justify-center' })"
				>
					<v-col
						v-for="(i, index) of productsLimit"
						:key="index"
						class="d-flex justify-center"
						:class="[{ 'px-0': $b.m, 'px-1': $b.td }, colSize]"
					>
						<ProductCard
							:type="cardType"
							small
							:product="i"
							:listId="listId"
							:listIndex="index"
							:verticalCard="
								!data.imgEnabled && productsLimit.length < 7 && $b.td
									? false
									: !data.imgEnabled && productsLimit.length == 1 && $b.m
									? false
									: data.imgEnabled && productsLimit.length <= 6 && $b.td
									? false
									: true
							"
							:class="{ imgSmall: colSize == 'col-2' }"
						/>
					</v-col>
				</v-row>
				<div v-else>
					<v-tabs
						background-color="transparent"
						show-arrows
						v-model="tab"
						centered
						center-active
						:color="$vars.cta"
						class="h100 carousel-multiple-pro__slider-top mb-3'"
						:class="{
							'carousel-multiple-pro__slider-right': $b.d,
							'carousel-multiple-pro__top-underline': $b.d,
						}"
						:slider-color="$vars.cta"
					>
						<v-tab v-for="(tabItem, n) in data.tabs" :key="n" active-class="cta--text">
							<v-icon v-if="data.titleType == 'icon'">{{ tabItem.titleIcon }}</v-icon>
							<Media v-else-if="data.titleType == 'img'" :src="tabItem.titleImg" />
							<span v-else>
								{{ tabItem.title }}
							</span>
						</v-tab>
					</v-tabs>
				</div>
				<div>
					<div>
						<div
							v-for="(item, n) in data.tabs"
							:key="n"
							class="tab"
							:class="{ 'active-tab': tab == n }"
						>
							<v-row
								v-if="tab == n"
								class="pa-1"
								:class="data.imgEnabled && item.products.length < 4 && $b({ t: 'justify-center' })"
							>
								<v-col
									v-for="(product, k) in item.products"
									:key="product.id"
									class="d-flex justify-center"
									:class="[{ 'px-0': $b.m, 'px-1': $b.td }, colSize]"
								>
									<ProductCard
										:type="cardType"
										small
										:product="product"
										:listId="listId"
										:listIndex="k"
										:verticalCard="
											!data.imgEnabled && productsLimit.length < 7 && $b.td
												? false
												: !data.imgEnabled && productsLimit.length == 1 && $b.m
												? false
												: data.imgEnabled && productsLimit.length <= 6 && $b.td
												? false
												: true
										"
										:class="{ imgSmall: colSize == 'col-2' }"
									/>
								</v-col>
							</v-row>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<div v-else class="remove-card-alignment d-flex justify-center">
			<v-row v-if="!data.enableTabs" :style="masonryMaxWidth">
				<v-col
					class="px-1 d-flex justify-center"
					:cols="12 / masonryCols"
					v-for="(group, n) in groupedProducts"
					:key="n"
				>
					<div>
						<v-hover
							v-for="(product, k) in group"
							v-on="hasHoverBtn && { input: (event) => onHover(event, n, k) }"
							:key="k"
						>
							<ProductCard
								:type="cardType"
								:ref="`card-${n}-${k}`"
								:small="$b.m"
								:product="product"
								:listId="listId"
								:listIndex="n + k"
								:style="{
									width: $b.m && '170px',
									height: currentHover == `${n}-${k}` ? currentHoverHeight : 'fit-content',
									'z-index': currentHover == `${n}-${k}` ? 1 : 0,
									position: 'relative',
								}"
								class="mb-2"
							/>
						</v-hover>
					</div>
				</v-col>
			</v-row>
			<div v-else>
				<div>
					<v-tabs
						background-color="transparent"
						show-arrows
						v-model="tab"
						centered
						center-active
						:color="$vars.cta"
						class="h100 carousel-multiple-pro__slider-top mb-3'"
						:class="{
							'carousel-multiple-pro__slider-right': $b.d,
							'carousel-multiple-pro__top-underline': $b.d,
						}"
						:slider-color="$vars.cta"
					>
						<v-tab v-for="(tabItem, n) in data.tabs" :key="n" active-class="cta--text">
							<v-icon v-if="data.titleType == 'icon'">{{ tabItem.titleIcon }}</v-icon>
							<Media v-else-if="data.titleType == 'img'" :src="tabItem.titleImg" />
							<span v-else>
								{{ tabItem.title }}
							</span>
						</v-tab>
					</v-tabs>
				</div>

				<div>
					<div v-for="(item, n) in data.tabs" :key="n" class="tab" :class="{ 'active-tab': tab == n }">
						<v-row :style="masonryMaxWidth" class="pa-1 pt-2" v-if="n == tab">
							<v-col
								class="px-1 d-flex justify-center"
								:cols="12 / masonryCols"
								v-for="(group, n) in groupedProducts"
								:key="n"
							>
								<div>
									<v-hover
										v-for="(product, k) in group"
										v-on="hasHoverBtn && { input: (event) => onHover(event, n, k) }"
										:key="k"
									>
										<ProductCard
											:type="cardType"
											:ref="`card-${n}-${k}`"
											:small="$b.m"
											:product="product"
											:listId="listId"
											:listIndex="n + k"
											:style="{
												width: $b.m && '170px',
												height: currentHover == `${n}-${k}` ? currentHoverHeight : 'fit-content',
												'z-index': currentHover == `${n}-${k}` ? 1 : 0,
												position: 'relative',
											}"
											class="mb-2"
										/>
									</v-hover>
								</div>
							</v-col>
						</v-row>
					</div>
				</div>
			</div>
		</div>
	</Container>
</template>

<style lang="scss" scoped>
.tab {
	transform: translateX(30px);
	opacity: 0;
	transition: all 0.3s ease-out;
}

.active-tab {
	opacity: 1;
	transform: translateX(0);
}

.background-overlay {
	background-color: #424242aa;
}

::v-deep .outlined {
	border: 1px solid var(--cta);
	font-size: 1.3em;
}

::v-deep .outlined-mobile {
	border: 1px solid var(--cta);
	font-size: 1em;
}

.remove-card-alignment {
	& ::v-deep {
		@each $type in ('vertical', 'small') {
			@for $i from 1 through 8 {
				.product-card-design#{$i}-#{$type} {
					&__name {
						min-height: unset !important;
						-webkit-line-clamp: unset !important;
					}
					&__price-container {
						min-height: unset !important;
					}

					&-wrapper {
						min-height: unset;
						&-forBtn {
							padding-bottom: 0;
						}
					}

					&__brand {
						min-height: unset;
					}

					&__brand-wrapper {
						min-height: unset;
						@if $i == 4 and $type == 'vertical' {
							min-height: 9px;
						} @else {
						}
					}

					&__financing-wrapper {
						min-height: unset;
					}
				}
			}
		}
	}
}
</style>
