<script>
export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
}
</script>

<template>
	<div>
		<v-row v-for="(row, n) in data.rows" :key="n">
			<v-col cols="12" :md="12 / row.banners.length" v-for="(banner, n) in row.banners" :key="n">
				<BannerItem v-bind="banner" />
			</v-col>
		</v-row>
	</div>
</template>

<style scoped lang="scss"></style>
