<script>
export default {
	props: {
		data: Object,
	},

	computed: {
		listId() {
			return `${this.data.collectionId}-${this.data.title}`
		},
	},

	mounted() {
		if (this.data.products?.length) {
			this.$eventer().trigger('product-list:view', {
				products: this.data.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Container
		v-if="data.products && data.products.length"
		class="align-center"
		:class="$bem('offers-plus-banners')"
	>
		<v-row v-if="$b.d">
			<v-col cols="5" class="py-1 pl-0 pr-2">
				<SafeLink :to="data.linkImgSq">
					<Media :src="data.imgSq" width="100%" aspectRatio="1" />
				</SafeLink>
			</v-col>

			<v-col cols="7" class="py-0 pr-0 pl-2">
				<div class="pb-2">
					<Title
						:title="data.title"
						:btn="{ link: data.link, linkText: data.linkText }"
						:titleStyle="data.titleStyle"
						titleMarginY="my-1"
					/>

					<div class="d-flex justify-space-around">
						<v-col v-for="(product, i) of data.products" :key="i" class="py-0 px-1">
							<ProductCard square :product="product" />
						</v-col>
					</div>
				</div>

				<div class="pt-2">
					<div class="d-flex flex-column w100">
						<SafeLink :to="data.linkImgHor" class="w100">
							<Media :src="data.imgHor" width="100%" />
						</SafeLink>
					</div>
				</div>
			</v-col>
		</v-row>

		<div v-else>
			<div class="w100">
				<SafeLink :to="data.linkImgSq">
					<Media :src="data.imgSq" width="100%" aspectRatio="1" />
				</SafeLink>
			</div>
			<div class="mb-2">
				<Title
					:title="data.title"
					:btn="{ link: data.link, linkText: data.linkText }"
					:titleStyle="data.titleStyle"
					titleMarginY="my-1"
				/>

				<div class="d-flex justify-space-around" v-if="$b.m">
					<v-col v-for="(product, i) of data.products.slice(0, 2)" :key="i" class="py-0 px-1">
						<ProductCard small :product="product" />
					</v-col>
				</div>
				<div class="d-flex justify-space-around col-gap mb-5" v-else>
					<v-col v-for="(product, i) of data.products" :key="i" class="pa-0">
						<ProductCard square :product="product" />
					</v-col>
				</div>
			</div>
			<div class="w100">
				<SafeLink :to="data.linkImgHor" class="w100">
					<Media :src="data.imgMobileHor" width="100%" />
				</SafeLink>
			</div>
		</div>
	</Container>
</template>

<style lang="scss" scoped>
.col-gap {
	column-gap: 7px;
}
</style>
