<script>
import numberedPropsToArray from '@/utils/numbered-props-to-array'

export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
	computed: {
		qtyImgMap() {
			return {
				2: { start: 2, end: 3 },
				3: { start: 4, end: 6 },
				4: { start: 7, end: 10 },
				5: { start: 11, end: 15 },
				6: { start: 16, end: 21 },
			}
		},
		start() {
			return this.qtyImgMap[this.qty]?.start
		},
		end() {
			return this.qtyImgMap[this.qty]?.end
		},
		bannerItems() {
			if (this.qty == 1) return numberedPropsToArray(this.data, 1)
			return numberedPropsToArray(this.data, this.end, this.start)
		},
		qty() {
			return this.data.imgQty
		},
	},
}
</script>

<template>
	<Container>
		<v-row v-if="qty == 1">
			<v-col>
				<BannerItem v-bind="bannerItems[0]" />
			</v-col>
		</v-row>
		<v-row v-else-if="qty == 2">
			<v-col cols="12" sm="6" v-for="(i, index) of bannerItems" :key="index">
				<BannerItem v-bind="i" />
			</v-col>
		</v-row>
		<v-row v-else-if="qty == 3">
			<v-col cols="12" sm="4" v-for="(i, index) of bannerItems" :key="index">
				<BannerItem v-bind="i" />
			</v-col>
		</v-row>
		<v-row align="center" v-else-if="qty == 4">
			<v-col cols="12" sm="5">
				<BannerItem v-bind="bannerItems[0]" />
			</v-col>

			<v-col cols="12" sm="4">
				<v-row>
					<v-col cols="12" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[1]" />
					</v-col>
					<v-col cols="12" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[2]" />
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" sm="3">
				<BannerItem v-bind="bannerItems[3]" />
			</v-col>
		</v-row>
		<v-row align="center" v-else-if="qty == 5">
			<v-col cols="12" sm="3">
				<BannerItem v-bind="bannerItems[0]" />
			</v-col>

			<v-col cols="6" v-if="$b({ smAndUp: true })">
				<v-row>
					<v-col cols="12" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[1]" />
					</v-col>
					<v-col cols="12" sm="6" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[2]" />
					</v-col>
					<v-col cols="12" sm="6" :class="{ 'py-2': $b.t }">
						<BannerItem v-bind="bannerItems[3]" />
					</v-col>
				</v-row>
			</v-col>

			<v-col v-else>
				<div>
					<BannerItem v-bind="bannerItems[1]" />
				</div>

				<div class="py-6">
					<BannerItem v-bind="bannerItems[2]" />
				</div>

				<div>
					<BannerItem v-bind="bannerItems[3]" />
				</div>
			</v-col>

			<v-col cols="12" sm="3">
				<BannerItem v-bind="bannerItems[4]" />
			</v-col>
		</v-row>
		<v-row v-else-if="qty == 6">
			<v-col cols="12" md="6">
				<v-row>
					<v-col cols="12">
						<BannerItem v-bind="bannerItems[0]" />
					</v-col>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[1]" />
					</v-col>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[2]" />
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" md="6">
				<v-row>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[3]" />
					</v-col>
					<v-col cols="12" sm="6">
						<BannerItem v-bind="bannerItems[4]" />
					</v-col>
					<v-col cols="12">
						<BannerItem v-bind="bannerItems[5]" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</Container>
</template>
