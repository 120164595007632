<script>
import axios from 'axios'
function _getPostImages(posts) {
	return posts.map((p) => {
		if (!p.image) return

		return axios
			.get(p.image)
			.then((res) => res.data)
			.then((data) => {
				p.image = data.guid.rendered
			})
	})
}

function _filterPostsByCategories(posts, categories) {
	return posts.filter((p) => p.categories.some((postCategory) => categories.includes(postCategory)))
}

function _buildPost(data, categories) {
	function _trimText(text) {
		let temp = text
			.split(/(<[^>]+>)/) // split by html tags
			.splice(0, 3) // remove html anchor and other unused data
			.join('')

		return temp
	}

	return {
		title: data.title.rendered,
		excerpt: _trimText(data.excerpt.rendered),
		link: data.link,
		image: data._links['wp:featuredmedia'][0]?.href || '',
		categories: data.categories.map((pc) => {
			const foundCategory = categories.find((c) => c.id === pc)
			return foundCategory.name
		}),
	}
}
export default {
	props: {
		data: Object,
		index: Number,
	},
	data() {
		return {
			loading: false,
			posts: [],
			error: false,
		}
	},
	created() {
		this.fetchPosts()
	},
	methods: {
		fetchPosts() {
			this.loading = true
			const endpoint = this.data.wordpressPosts.domain + '/wp-json/wp/v2'
			const promises = [
				axios.get(endpoint + '/posts').then((res) => res.data),
				axios.get(endpoint + '/categories').then((res) => res.data),
			]

			Promise.all(promises)
				.then(([posts, categories]) => {
					const shouldFilterByCategories =
						this.data.wordpressPosts.categories.length !== categories.length
					if (shouldFilterByCategories) {
						posts = _filterPostsByCategories(posts, categories)
					}
					posts = posts
						.splice(0, this.data.wordpressPosts.designColumns)
						.map((post) => _buildPost(post, categories))
					return Promise.all(_getPostImages(posts)).then(() => posts)
				})
				.then((posts) => {
					this.posts = posts
				})
				.catch(() => {
					this.error = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
	computed: {
		getColumns() {
			if (this.data.wordpressPosts.designColumns === 4) {
				return {
					sm: '6',
					md: '6',
				}
			}

			if (this.data.wordpressPosts.designColumns === 3) {
				return {
					sm: '4',
				}
				// if (index === 2) {
				// 	return {
				// 		sm: '12',
				// 	}
				// } else {
				// 	return {
				// 		sm: '6',
				// 	}
				// }
			}

			if (this.data.wordpressPosts.designColumns === 2) {
				return {
					md: '6',
				}
			}

			return {
				sm: '4',
			}
		},
		containerStyles() {
			if (this.error) return

			if (this.data.wordpressPosts.designColumns === 3) {
				if (this.$b.xsOnly) {
					return { height: '1400px' }
				}
				if (this.$b.smAndDown) {
					return { height: '556px' }
				}
				if (this.$b.lgOnly) {
					return { height: '496px' }
				}
				return { height: '497px' }
			}
			if (this.data.wordpressPosts.designColumns === 4) {
				if (this.$b.xsOnly) {
					return { height: '1868px' }
				}
				if (this.$b.smAndDown) {
					return { height: '963px' }
				}
				if (this.$b.mdAndUp) {
					return { height: '905px' }
				}
			}
			if (this.data.wordpressPosts.designColumns === 2) {
				if (this.$b.xsOnly) {
					return { height: '1116px' }
				}
				if (this.$b.smOnly) {
					return { height: '873px' }
				}
				if (this.$b.mdAndUp) {
					return { height: '362px' }
				}
			}
			return null
		},
		listId() {
			return 'wordpress-posts'
		},
	},
	mounted() {
		this.$eventer().trigger('wordpress:view', {
			posts: this.posts,
			listId: this.listId,
		})
	},
}
</script>

<template>
	<Container :style="containerStyles" class="base" v-if="!error">
		<div v-if="loading" class="d-flex justify-center py-16 mt-8">
			<v-progress-circular :size="70" :width="7" indeterminate />
		</div>
		<v-row justify="center" v-else-if="posts.length">
			<v-col cols="12" v-bind="getColumns" v-for="(post, i) in posts" :key="i">
				<SafeLink :to="post.link" target="_blank">
					<WordpressPostItemHorizontal :post="post" v-if="data.wordpressPosts.designColumns === 2" />
					<WordpressPostItem :post="post" v-else />
				</SafeLink>
			</v-col>
		</v-row>
	</Container>
</template>

<style lang="scss" scoped>
.wordpress-post-card {
	border: 1px solid #eee;
	height: 100%;

	&__image {
		width: 100%;
		max-height: 282px;
		object-fit: cover;
	}
}
</style>
