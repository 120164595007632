<script>
export default {
	inheritAttrs: false,
	props: {
		index: Number,
		data: Object,
	},
}
</script>

<template>
	<Container>
		<div v-html="data.content" />
	</Container>
</template>

