<script>
function _normalizeColor(hex) {
	if (!hex) return null
	if (!hex.startsWith('#')) return hex
	hex = hex.slice(0, 9).toUpperCase()
	if (hex.length == 4 || hex.length == 5) {
		hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}${hex[4] || ''}${hex[4] || ''}`
	}
	return hex
}

export default {
	props: {
		items: Array,
		cssVarsKey: String,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		cItems() {
			if (!this.items) return []
			return this.items.filter((item) => !!item)
		},
	},
	methods: {
		getCssVarClass(i) {
			return `dp-${this.cssVarsKey}-${this.cItems[i].cid}`
		},
		getWrapperPaddingClasses(i) {
			if (this.cItems[i].noPaddingY) return ''
			let color = _normalizeColor(this.cItems[i].cssVars?.base)
			let prevColor = _normalizeColor(this.cItems[i - 1]?.cssVars?.base)
			let nextColor = _normalizeColor(this.cItems[i + 1]?.cssVars?.base)
			let p = 3
			let pt = p
			if (i == 0 || color != prevColor) pt *= 2
			let pb = p
			if (i == this.cItems.length - 1 || color != nextColor) pb *= 2
			return `pt-${pt} pb-${pb}`
		},
		getBackgroundStyles(data) {
			let mediaSrc = this.$b.mdAndUp
				? data.backgroundImage
				: data.backgroundImageMobile || data.backgroundImage
			if (!mediaSrc) return null
			let { src } = this.$media(mediaSrc)
			let styles = {}
			styles.backgroundImage = `url('${src}')`
			styles.backgroundPosition = 'center'
			styles.backgroundSize = 'cover'
			//styles.backgroundColor = data.backgroundColor
			return styles
		},
		getBaseClass(data) {
			if (data.backgroundImage || data.backgroundImageMobile) return 'base--color'
			return 'base'
		},
	},
}
</script>

<template>
	<div v-if="cItems.length">
		<css-vars
			v-for="(data, index) of cItems"
			:key="`${cssVarsKey}_${index}`"
			:selector="`.${getCssVarClass(index)}`"
			:default-vars="data.cssVars"
		>
			<div
				:class="[getCssVarClass(index), getWrapperPaddingClasses(index), getBaseClass(data)]"
				:style="getBackgroundStyles(data)"
			>
				<component :is="`dpc-${data.schemaKey}`" v-bind="{ data, index }" />
			</div>
		</css-vars>
	</div>
</template>
<style lang="scss" scoped>
.base--color {
	color: var(--basetext);
}
</style>

